@import "../../utils/commons.scss";

.container {
  position: relative;
  //   background-color: var(--clr-bg-dark);
  // margin-top: var(--nav-height);
  margin: 2rem auto;
  margin-bottom: 0;
  form {
    background-color: #1f2226;
    border-radius: 20px;
    // margin: 3rem 4rem;
    padding: 3rem 4rem;
  }
  .formSection {
    margin: 2rem 0;
  

    .header {
      width: -moz-fit-content;
      width: fit-content;
      @extend .flexRow;
      margin-bottom: 1rem;
    }
    .sectionNumber {
      @extend .flexRow;
      justify-content: center;
      padding: 1rem;
      width: 50px;
      height: 50px;
      border: 2px solid #ffffff;
      border-radius: 50%;
      margin-right: 1rem;
    }
    .sectionName{
      font-family: Gilroy-500;
    }
  }
  .personInContact{
    margin-top:3rem;
      width:100%;
      display:grid;
      grid-template-columns: 350px 350px;
  }
  .inputs{
    margin-left: 60px;
  }
  .radioButtonGroup{
    @extend .flexRow;
    align-items: flex-end;
    width: fit-content;
    >*{
      margin-right: 1rem;
      
    }
    > :last-child{
      margin-left: 2rem !important;
    }
  }
}
